<template>
  <div class="announcement">
    <img src="@/assets/images/gg.jpg" width="100%" alt="" />
  </div>
</template>

<script>
export default {
  name: "announcement",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.announcement {
  background-color: #77060e;
  height: 100%;
  padding-top: 46px;
}
</style>
